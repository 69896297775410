import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import logo from "./logo.png"; // Replace with your actual logo path

const Home = () => {
  return (
    <div className="app">
      <header className="header">
        <img src={logo} alt="TidyHub Logo" className="logo" />
      </header>

      <div className="download-section">
        <h2>Download Our App</h2>
        <p>Your On-Demand Laundry Solution in the UAE</p>
        <p>Available on both Android and iOS platforms.</p>
        <div className="store-buttons">
          <a
            href="https://play.google.com/store/apps/details?id=com.tidyhublaundry.ae"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Get it on Google Play"
              className="store-badge"
            />
          </a>
          <a
            href="https://apps.apple.com/app/tidyhub-laundry/id6738999578"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png"
              alt="Download on the App Store"
              className="store-badge"
            />
          </a>
        </div>
      </div>
      {/* Footer Section */}
      <footer className="footer">
        <p>&copy; 2024 TidyHub Laundry. All rights reserved.</p>
      </footer>
    </div>
  );
};

const LinkData = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPhone|iPad|iPod/.test(userAgent)) {
      // Redirect to Apple App Store
      window.location.href =
        "https://apps.apple.com/app/tidyhub-laundry/id6738999578";
    } else if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.tidyhublaundry.ae";
    } else {
      // Redirect to TidyHub if on a PC
      window.location.href = "https://tidyhub.ae";
    }
  }, []);
  return (
    <div style={{ textAlign: "center", marginTop: "20%" }}>
      <h1>Redirecting to the app store...</h1>
      <p>
        If you are not redirected automatically,{" "}
        <a href="https://www.example.com">click here</a>.
      </p>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/link" element={<LinkData />} />
      </Routes>
    </Router>
  );
};

export default App;
